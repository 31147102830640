<!--
 * @Descripttion: 投票创建组件
 * @version: 1.0.0
 * @Author: tangjz
 * @Date: 2023-03-08 18:12:49
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-10-09 13:45:16
-->

<template>
  <div class="vote-dialog">
    <div class="vote-content">
      <p class="title">发起投票</p>
      <span class="vote-close" @click="close"></span>
      <el-scrollbar max-height="70vh" :min-size="10" ref="voteRef">
        <el-form :model="voteForm" label-width="90px" :rules="voteRules" ref="voteFormRef">
          <el-form-item v-for="{label, key, list} in voteSetting.filter(i => i.key !== 'options')" :key="key" :label="`${label}:`" :prop="key">
            <template v-if="key === 'title'">
              <el-input v-model="voteForm[key]" placeholder="请输入" clearable :maxlength="50"/>
            </template>
            <template v-if="key === 'is_option_fixed' || key === 'is_anonymous' || key === 'max_vote_nums' ">
              <el-radio-group v-model="voteForm[key]">
                <el-radio v-for="{value, name} in list" :key="value" :label="value">{{name}}</el-radio>
              </el-radio-group>
              <div class="limit-num" v-if="key === 'max_vote_nums' && voteForm['max_vote_nums'] === 2" >
                <el-input-number v-model="limitNum" size="small" :min="2" :max="maxOptionNum"/>
                <p>封闭式投票时，数量不大于投票选项数</p>
              </div>
            </template>
            <template v-if="key === 'deadline_time'">
              <el-date-picker
                :default-value="defaultTime"
                :disabled-date="disabledDate"
                placeholder="请输入"
                format="YYYY-MM-DD HH:mm"
                value-format="YYYY-MM-DD HH:mm"
                v-model="voteForm[key]"
                type="datetime"
                popper-class="select-time"
                clearable
                @change="getDateTime"
                />
            </template>
            <template v-if="key === 'scope'">
              <el-checkbox-group v-model="voteForm[key]" class="scope-input"></el-checkbox-group>
              <p :class="['scope-desc', {'current-text': receiveUserName}]" @click="getReceiveUser">{{ receiveUserName || '选中用户可见' }}</p>
            </template>
          </el-form-item>
          <template v-if="!voteSetting.find(i => i.key === 'options').isHide">
            <el-form-item
              v-for="(item, index) in voteForm.options"
              :key="index"
              :label="`${index === 0 ? '投票内容:' : ' '}`"
              :prop="`options.${index}`"
              :rules= "[{ required: true, message: '投票选项不能为空', validator: (rule, value, callback) => checkNull(rule, value, callback, '投票选项'), trigger: ['blur', 'change'] }]"
              class="options-list">
              <div :class="['vote-text']">
                <el-input
                  placeholder="请输入"
                  v-model="voteForm.options[index].title"
                  clearable
                  :maxlength="50"
                  :type="voteForm.options[index].img_url.length === 0 ?  'text': 'textarea' "
                  :rows="6"
                />
                <UploadFile
                  @uploadFileInf="(type, file) => addImage(file, index)"
                  >
                  <template #mySlot>
                    <i class="upload-icon">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.833 8.75v7.916c0 .46-.373.834-.833.834H3.333a.833.833 0 0 1-.833-.834V5c0-.46.373-.833.833-.833h7.652" stroke="#333" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M5 13l2.4-3.813 1.2 1.424L10 8l3 5H5zM14.166 4.167H17.5M15.831 2.415v3.333" stroke="#333" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </i>
                  </template>
                </UploadFile>
                <div class="image-box" v-if="voteForm.options[index].img_url.length > 0">
                  <div class="content-box">
                    <el-image
                      :src="voteForm.options[index].img_url"
                      :preview-src-list="[voteForm.options[index].img_url]"
                      ></el-image>
                    <p>{{voteForm.options[index].img_name}}</p>
                  </div>
                  <DeleteIcon
                    deleteText="这张图片"
                    :isTextShow="false"
                    @handleClick="deleteImage(index)">
                  </DeleteIcon>
                </div>
                <i class="cut-icon" @click="delOption" v-if="index === voteForm.options.length - 1 && voteForm.options.length > 2">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="3" y="9" width="14" height="2" rx="1" fill="#333"/></svg>
                </i>
              </div>
            </el-form-item>
            <p class="add-icon" @click="addOption">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="3" y="9" width="14" height="2" rx="1" fill="#333"/><rect x="11" y="3.002" width="14" height="2" rx="1" transform="rotate(90.068 11 3.002)" fill="#333"/></svg>
              <span>添加选项</span>
            </p>
          </template>
        </el-form>
      </el-scrollbar>
      <div class="vote-btn">
        <el-button type="primary" size="small" @click="close">取消</el-button>
        <el-button type="primary" size="small" @click="toCreateVote">确定</el-button>
      </div>
    </div>
    <el-dialog custom-class="vote-select-user" v-model="receiveVisible" center :show-close="false" @close="hideReceiveUser" append-to-body>
      <div class="select-user">
        <p class="user-dialog-title">选择可见范围</p>
        <SelectUser ref="receiveUserRef"
          :userIds="receiveUserIds"
          :disableUserIds="disableUserIds"
          :currentUser="currentUser"
          :noMaxNum="true"
          >
        </SelectUser>
        <div class="button-box">
          <el-button @click="hideReceiveUser" type="primary" size="small">取消</el-button>
          <el-button type="primary" size="small" @click="addReceiveUser">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, toRefs, computed, nextTick, watch } from 'vue'
import { useStore } from 'vuex'
import { formatAllDate } from '@/utils/voteUtil.js'
import SelectUser from '@/views/layout/work/writeJournal/component/SelectUser.vue'
import { getTeamUserList } from '@/apis/journal.js'
import { reBackTime } from '@/utils/tools'
import DeleteIcon from '@/components/icon/DeleteIcon.vue'
export default {
  components: { SelectUser, DeleteIcon },
  setup (props, { emit }) {
    const store = useStore()
    const checkNull = (rule, value, callback, name) => {
      const content = name === '投票选项' ? value.title : value
      if (!content || !content.trim()) {
        return callback(new Error(`${name}不能为空`))
      } else {
        callback()
      }
    }
    const state = reactive({
      voteFormRef: null,
      voteSetting: [
        {
          key: 'title',
          label: '投票标题'
        },
        {
          key: 'is_option_fixed',
          label: '投票类型',
          list: [
            {
              value: 0,
              name: '开放式投票'
            },
            {
              value: 1,
              name: '封闭式投票'
            }
          ]
        },
        {
          key: 'is_anonymous',
          label: '投票方式',
          list: [
            {
              value: 0,
              name: '实名'
            },
            {
              value: 1,
              name: '匿名'
            }
          ]
        },
        {
          key: 'max_vote_nums',
          label: '投票数量',
          list: [
            {
              value: 1,
              name: '单选'
            },
            {
              value: 2,
              name: '多选'
            },
            {
              value: 999999,
              name: '不限'
            }
          ]
        },
        {
          key: 'deadline_time',
          label: '截止时间'
        },
        {
          key: 'scope',
          label: '可见范围'
        },
        {
          key: 'options',
          label: '投票内容',
          isHide: computed(() => state.voteForm.is_option_fixed === 0)
        }
      ],
      voteForm: {
        id: 0,
        is_option_fixed: 0,
        is_anonymous: 0,
        title: '',
        max_vote_nums: 1,
        deadline_time: '',
        scope: computed(() => state.userIds),
        options: [{ title: '', img_url: '', img_name: '' }, { title: '', img_url: '', img_name: '' }]
      },
      voteRules: {
        title: [{ required: true, validator: (rule, value, callback) => checkNull(rule, value, callback, '标题'), trigger: ['blur', 'change'] }],
        deadline_time: [{ required: true, validator: (rule, value, callback) => checkNull(rule, value, callback, '截止时间'), trigger: 'change' }],
        is_option_fixed: [{ required: true }],
        is_anonymous: [{ required: true }],
        max_vote_nums: [{ required: true }],
        scope: [{ type: 'array', required: true, message: '可见范围不能为空', trigger: 'change' }]
      },
      receiveVisible: false,
      receiveUserRef: null,
      receiveList: [],
      receiveUserIds: computed(() => state.receiveList.map(item => item.ding_user_id || item.id)),
      receiveUserName: computed(() => state.receiveList.map(item => (item.name || item.value)).join('、')),
      userIds: computed(() => state.receiveList.map(item => item.id)),
      disableUserIds: computed(() => state.receiveList.filter(item => item.disabled).map(item => item.ding_user_id || item.id)),
      currentUser: computed(() => store.state.userInfo.userInfos),
      formatUser: computed(() => {
        return {
          id: state.currentUser.id,
          ding_user_id: state.currentUser.user_id,
          name: state.currentUser.name,
          avatar: state.currentUser.avatar,
          disabled: true
        }
      }),
      limitNum: 2, // 限制输入人数
      maxOptionNum: computed(() => state.voteForm.is_option_fixed ? state.voteForm.options.length : 99999), // 限制输入最大值
      timeFlag: false,
      defaultTime: computed(() => {
        const now = new Date()
        const date = reBackTime(new Date(now.getTime() + 25 * 60 * 60 * 1000).getTime())
        return new Date(date.year, date.month, date.day, date.hours, 0, 0)
      }),
      voteRef: null,
      allUserList: computed(() => store.state.userList)
    })
    // 切换封闭式票数初始化
    watch(() => state.voteForm.is_option_fixed, (newValue) => {
      newValue && (state.limitNum = 2)
    })
    // 创建投票弹窗初始化
    watch(() => store.state.vote.voteVisible, (newValue) => {
      newValue && initData()
    })
    // 清理投票创建次数
    watch(() => store.state.vote.clearNum, (newValue) => {
      resetVoteData()
    })
    // 初始化回显投票数据
    const initData = () => {
      const now = new Date()
      const date = reBackTime(new Date(now.getTime() + 25 * 60 * 60 * 1000).getTime())
      const timeDate = `${date.year}-${date.MM}-${date.DD} ${date.hh}:00`
      // eslint-disable-next-line camelcase
      if (Object.keys(store.state.vote.voteInfos).length > 0) {
        state.voteForm = Object.assign(state.voteForm, store.state.vote.voteInfos)
        const maxVoteNum = state.voteForm.max_vote_nums
        state.limitNum = maxVoteNum
        state.voteForm.max_vote_nums = (maxVoteNum > 1 && maxVoteNum !== 999999) ? 2 : maxVoteNum
        // 投票选项特别处理，接口返回的数据需要取title字段，前端存的直接取
        state.voteForm.options = state.voteForm.options.length > 0 ? state.voteForm.options : [{ title: '', img_url: '', img_name: '' }, { title: '', img_url: '', img_name: '' }]
        state.receiveList = state.allUserList.filter(item => store.state.vote.voteInfos.scope.includes(item.id))
        // 自己不可删除
        state.receiveList.filter(item => item.id === state.formatUser.id)[0].disabled = true
      }
      const deadlineTime = state.voteForm.deadline_time || store.state.vote.voteInfos.deadline_time
      state.voteForm.deadline_time = deadlineTime && new Date().getTime() < new Date(deadlineTime).getTime() ? deadlineTime : timeDate
    }
    initData()

    // 时间禁用
    const disabledDate = (time) => {
      return time.getTime() < new Date().getTime() - 24 * 60 * 60 * 1000
    }
    // 格式化选择时间
    const getDateTime = (value) => {
      if (!value) return
      if (new Date(value).getTime() <= new Date().getTime()) {
        state.voteForm.deadline_time = formatAllDate(value, null, 'day') + ' ' + (new Date().getHours() + 1) + ':00'
      } else {
        value.substr(-2) === '00' || (state.voteForm.deadline_time = formatAllDate(value, null, 'hour') + ':00')
      }
    }
    const getReceiveUser = () => {
      state.receiveVisible = true
      if (state.receiveList.find(i => i.ding_user_id === state.formatUser.ding_user_id || (i.id === state.formatUser.ding_user_id))) return
      state.receiveList.push(state.formatUser)
    }

    // 选中用户范围
    const addReceiveUser = () => {
      state.receiveList = [] // 先清空，重新渲染选择的用户，避免漏删除
      state.receiveList.push(state.formatUser) // 自己排前面
      state.receiveUserRef.rightData.forEach(item => {
        if (item.dept_id) {
          getTeamUserList({ dept_id: item.dept_id }).then(res => {
            res.data.forEach(user => {
              setReceiveUser(user)
            })
          })
        } else {
          setReceiveUser(item)
        }
      })
      hideReceiveUser()
    }
    const setReceiveUser = (item) => {
      const user = JSON.parse(JSON.stringify(item))
      // 去重添加
      !state.receiveUserIds.includes(user.ding_user_id) && state.receiveList.push(user)
    }
    const hideReceiveUser = () => {
      state.receiveVisible = false
      // 重置数据
      state.receiveUserRef.resetData()
    }

    const resetVoteData = () => {
      state.voteFormRef.resetFields()
      state.receiveList = []
      state.voteForm.is_option_fixed = 0
      state.voteForm.is_anonymous = 0
      state.voteForm.title = ''
      state.voteForm.max_vote_nums = 1
      state.voteForm.deadline_time = ''
      state.voteForm.options = [{ title: '', img_url: '', img_name: '' }, { title: '', img_url: '', img_name: '' }]
    }

    // 创建投票
    const toCreateVote = () => {
      console.log('提交')
      state.voteFormRef.validate((valid) => {
        if (valid) {
        // eslint-disable-next-line camelcase
          const { is_option_fixed, is_anonymous, title, max_vote_nums, deadline_time, scope, options, id } = state.voteForm
          const params = { is_option_fixed, is_anonymous, title, max_vote_nums, deadline_time, scope, options, id }
          params.max_vote_nums = params.max_vote_nums === 2 ? state.limitNum : params.max_vote_nums
          params.options = params.is_option_fixed === 0 ? [] : params.options
          store.commit('vote/setVoteVisible', false)
          store.commit('vote/setVoteInfo', params)
        }
      })
    }
    // 取消创建投票
    const close = () => {
      store.commit('vote/setVoteVisible', false)
      !store.state.vote.voteInfos.scope && resetVoteData()
    }

    // 删除投票选项
    const delOption = () => {
      state.voteForm.options.pop()
      state.limitNum = state.limitNum > state.voteForm.options.length ? state.voteForm.options.length : state.limitNum
    }
    // 添加投票选项
    const addOption = async () => {
      state.voteForm.options.push({ title: '', img_url: '', img_name: '' })
      await nextTick()
      state.voteRef.wrap.scrollTop = state.voteRef.wrap.scrollHeight
    }

    const addImage = (file, index) => {
      if (file?.url) {
        state.voteForm.options[index].img_url = file.url
        state.voteForm.options[index].img_name = file.name
      }
    }

    const deleteImage = (index) => {
      state.voteForm.options[index].img_url = ''
      state.voteForm.options[index].img_name = ''
    }

    return {
      ...toRefs(state),
      toCreateVote,
      close,
      getReceiveUser,
      addReceiveUser,
      hideReceiveUser,
      disabledDate,
      getDateTime,
      delOption,
      addOption,
      addImage,
      deleteImage,
      checkNull
    }
  }
}
</script>
<style lang="less">
.select-time{
  transform: translateY(-10px);
  .el-popper__arrow{
    display: none;
  }
  .el-time-spinner__wrapper{
    width: 100%;
    &:last-child{
      display: none;
    }
  }
  .el-time-panel{
    width: 100px;
  }
  .el-button--text{
    display: none;
  }
}
.vote-select-user{
  width: 651px !important;
  .el-dialog__header, .el-dialog__body{
    padding: 0;
  }
}

.select-user{
  position: absolute;
  background: #F6F9FA;
  padding: 16px 24px 12px;
  z-index: 5;
  border-radius: 6px;
  .user-dialog-title{
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: @default-text-color;
    margin-bottom: 16px;
  }
  .el-dialog__body{
    padding: 6px 24px;
    font-size: 13px;
    color: @default-text-color;
  }
  .el-dialog__title{
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
  }
  .el-button{
    font-size: 14px;
  }
  .el-input__inner{
    padding-right: 45px;
  }
  .el-scrollbar{
    padding: 16px 10px 20px 16px !important;
    .el-input__inner{
      width: 266px;
      height: 33px;
      line-height: 33px;
    }
    .search-box{
      svg{
        top: 7px;
      }
    }
    .el-breadcrumb__inner{
      color: @default-text-color;
    }
    li{
      .el-checkbox{
        margin-right: 6px;
      }
      &:first-child{
        .el-checkbox{
          margin-right: 15px;
        }
      }
      .dep-item,
      .user-info{
        padding: 8px 0 8px 10px;
      }
      .user-info:hover,
      &.rigth-item:hover{
        cursor: pointer;
        background: #F1F4F4;
        border-radius: 6px;
      }
    }
  }
  .button-box{
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
    .el-button{
      width: 85px;
      height: 32px;
    }
    .el-button:first-child{
      background: #E4E3FC;
      border-radius: 30px;
      color: @active-text-color;
    }
  }
  input::-webkit-input-placeholder,
  input::-moz-input-placeholder,
  input::-ms-input-placeholder {
    color: #333;
    font-size: 13px;
  }
  .el-input__inner{
    width: 100%;
    height: 30px;
    background: #F6F9FA;
    border: none;
    border-radius: 25px;
    line-height: 30px;
    cursor: pointer;
  }
}

</style>
<style lang="less" scoped>
  .vote-dialog{
    padding: 24px 20px;
    color: @default-text-color;
    cursor: auto;
    // max-height: 70vh;
    position: relative;
    .title{
      color: #000;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .vote-close{
      display: block;
      position: absolute;
      top: 20px;
      right: 20px;
      width: 10px;
      height: 10px;
      background: url('../../../../assets/img/vote/close-icon.svg') no-repeat;
      cursor: pointer;
    }

    :deep(.el-form-item){
      margin-bottom: 20px;
      &:nth-child(n+6) .el-form-item__error{
        transform: scale(.9);
        margin-left: -5px;
        padding-top: 2px;
      }
      &:nth-child(n+7){
        margin-bottom: 16px;
      }
      &:nth-child(n+8) label{
        opacity: 0;
      }
      &:last-child{
        margin-bottom: 20px;
      }
    }
    :deep(.el-form-item__label){
      margin-right: 10px;
      color: #000;
      font-size: 15px;
    }
    :deep(.el-form-item__content){
      // max-width: 420px;
      width: 100%;
    }
    :deep(.el-radio){
      width: 100px;
    }
    :deep(.el-form-item.is-error .el-input__inner){
      border-color: var(--el-color-danger);
    }
    :deep(.el-input__inner){
      padding: 9px 35px 9px 12px;
      border-radius: 4px;
      border: 1px solid #DCDFE6;
      max-width: 420px;
      cursor: pointer;
    }
    :deep(.el-radio__label) {
      padding-left: 6px;
      margin-bottom: 5px;
      display: inline-block;
    }
    :deep(.el-radio__inner){
      width: 16px;
      height: 16px;
      border-radius: 2px;
      border-color: var(--el-color-primary);
    }
    :deep(.el-radio__input.is-checked .el-radio__inner::after){
      border-radius: 0px;
      width: 14px;
      height: 14px;
      background: #7972F0 url('../../../../assets/img/vote/selected-white-icon.svg') no-repeat center;
    }
    .limit-num{
      display: flex;
      margin-top: 5px;
      line-height: 1;
      align-items: center;
      &>p{
        font-size: 12px;
        color: #C0C4CC;
        margin-left: 10px;
        margin-top: 10px;
      }
      :deep(.el-input__inner){
        padding-left: 35px;
      }

    }

    :deep(.el-date-editor){
      width: 100%;
      position: relative;
      width: 420px;
      .el-input__inner{
        border-radius: 4px;
        border: 1px solid #DCDFE6;
      }
      .el-input__prefix{
        position: absolute;
        right: 13px;
        left: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        .el-input__icon {
          background: url(../../../../assets/img/datepicter-icon.svg) no-repeat;
          width: 20px;
          height: 20px;
        }
      }
      .el-input__suffix{
        right: 35px;
      }
      .el-icon-time:before{
        content: none;
      }
    }
    .scope-input{
      display: none;
    }
    .scope-desc{
      position: relative;
      padding: 9px 30px 9px 12px;
      border-radius: 4px;
      border: 1px solid #DCDFE6;
      height: 40px;
      line-height: 40px;
      color: #C0C4CC;
      font-size: 14px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      max-width: 420px;
      &::before{
        content: '';
        position: absolute;
        top: 16px;
        right: 16px;
        background: url(../../../../assets/img/arrow-down-icon.svg) no-repeat;
        width: 12px;
        height: 8px;
      }
      &.current-text{
        color: var(--el-text-color-regular);
      }
    }

    .vote-text{
      position: relative;
      display: flex;
      :deep(.el-upload){
        position: absolute;
        top: 10px;
        right: 36px;
        line-height: 20px;
        display: none;
      }
      &:hover{
        :deep(.el-upload){
          display: inline-block;
        }
      }
      :deep(.el-input), :deep(.el-textarea){
        width: 420px;
        .el-input__inner,.el-textarea__inner{
          padding-right: 45px;
        }
        .el-input__suffix{
          right: 30px;
        }
      }
    }

    .cut-icon,.add-icon,.upload-icon{
      cursor: pointer;
      display: flex;
      align-items: center;
      // justify-content: center;
    }
    .add-icon{
      margin-left: 100px;
    }
    .image-box{
      position: absolute;
      display: flex;
      justify-content: space-between;
      bottom: 0;
      width: 400px;
      height: 60px;
      padding: 10px;
      margin: 10px;
      border-radius: 4px;
      background: #f1f1f1;
      .el-image{
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }
      .content-box{
        display: flex;
      }
    }
    :deep(
    .el-input__inner::-webkit-input-placeholder,
    .el-input__inner::-moz-input-placeholder,
    .el-input__inner::-ms-input-placeholder){
      color: #C0C4CC;
      font-size: 14px;
    }
    .vote-btn{
      display: flex;
      justify-content: flex-end;
      .el-button{
        width: 85px;
        height: 32px;
      }
      .el-button:first-child{
        background: #E4E3FC;
        border-radius: 30px;
        color: @active-text-color;
      }
    }

  }

</style>
